<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del punto de venta" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.pos.retrieveLoader, text: instance.custom_id ? `Información del ${instance.custom_id}` : 'Información del pos' }" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="env === 'ax'" color="primary" :to="{ name: 'LogsList', query: { pos: instance.id }, params: $route.params }"><v-icon left>mdi-alert-circle</v-icon>Ver LOGS</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <VSkeleton v-if="$store.state.branches.retrieveLoader || $store.state.pos.retrieveLoader" />
    <template v-else>
      <v-col class="px-0" style="z-index: 0;">
        <div class="d-flex">
          <!-- detail card -->
          <div class="mr-6" style="min-width: 424px;">
            <v-card class="pa-0" width="424" transition="slide-y-transition" min-height="calc(100% - 150px)" flat style="position:fixed!important;">
              <VCardMapDetail :carouselList="['', instance.photo]" :itemList="cardMapDetail" @handleModal="handleEditConfigPos" :hiddenEdit="true" />
            </v-card>
          </div>
          <!-- detail card -->

          <!-- panels -->
          <div class="pb-5" style="flex:1; min-width: 180px;">
            <v-toolbar class="mt-n2" color="transparent" flat height="36">
              <v-toolbar-title class="subtitle-1 fontBody--text font-weight-bold align-center d-flex">
                <v-icon class="mr-1" color="secondary" size="20">mdi-store</v-icon> {{ branchList.name || 'Sin definir'}}
                <v-icon class="mx-1" color="secondary" size="20">mdi-chevron-right</v-icon>
                <v-icon class="mx-1" color="secondary" size="20">mdi-printer-pos</v-icon>
                {{ instance.custom_id || 'Sin definir'}}
              </v-toolbar-title>
            </v-toolbar>
            <div class="d-flex mt-2">
              <div class="pb-3" style="min-width: 230px;">
                <v-card class="mr-5" flat height="100%">
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="46">
                      <v-toolbar-title class="px-3 subtitle-2 fontBody--text font-weight-semibold align-center d-flex">
                        <v-icon size="18" left color="secondary">mdi-traffic-light</v-icon>Estado
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="pb-4 pt-5 px-4" style="max-height: 600px !important;">
                    <div class="mb-4" v-for="s in status" :key="s.type">
                      <v-chip :color="s.background" label small><span class="font-weight-medium">{{s.type}}</span> <v-icon :color="s.color" right>mdi-{{ s.icon }}</v-icon></v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div style="flex:1; min-width: 180px;">
                <!-- status -->
                <div v-for="s in status" :key="s.type">
                  <v-card class="mb-6" flat v-if="s.type !== 'Certificado digital'">
                    <v-card-text class="pa-0 px-3 mx-auto">
                      <v-row class="pt-3 px-2" align="center" no-gutters>
                        <v-col>
                          <span class="font-weight-bold body-1 secondary--text mb-1 d-block">{{ s.title }}</span>
                        </v-col>
                        <v-spacer />
                        <v-col class="text-right">
                          <span :class="`${s.color}--text body-2 mb-1 d-block`">
                            <v-icon size="10" :color="s.color">mdi-circle</v-icon>
                            {{ s.status }}
                          </span>
                        </v-col>
                      </v-row>
                      <div class="d-flex justify-space-between">
                        <div class="pa-0 d-inline-flex justify-center" v-for="item in 30" :key="item" style="width: 100%; height: 100%">
                          <v-sheet :color="s.upTimeList[item]" :width="autoWidth" style="border-radius: 100px;" :style="`height:${autoHeight}px !important`" :max-height="40" />
                        </div>
                      </div>
                      <v-row class="mt-1 pb-0 px-2" align="center">
                        <v-col class="pt-0 body-2 secondary--text">{{ s.startDate }}</v-col>
                        <v-col cols="1" class="px-0"><v-divider class="py-1"/></v-col>
                        <v-col class="pt-0 body-2 font-weight-medium secondary--text text-center" style="min-width: 40%">{{s.percent}}% de dias con {{s.type}}</v-col>
                        <v-col cols="1" class="px-0"><v-divider class="py-1" /></v-col>
                        <v-col class="pt-0 body-2 secondary--text text-right">Hoy</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
                <!-- end status -->
              </div>
            </div>
            <v-expansion-panels class="mt-2" v-model="panelTransactional">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="pa-0">
                      <span class="ml-n1 subtitle-2 secondary--text font-weight-bold align-center d-flex"><v-icon size="18" left color="secondary">mdi-chart-areaspline</v-icon>Estadísticas transaccionales</span>
                    </v-col>
                    <v-spacer />
                    <v-menu v-if="panelTransactional === 0" close-on-click close-on-content-click offset-y :nudge-bottom="8" left transition="slide-y-transition">
                      <template v-slot:activator="{on, attrs}">
                        <v-btn class="body-1 mr-4" v-on="on" v-bind="attrs" outlined :ripple="false">
                          <v-icon left>mdi-calendar-blank</v-icon>
                          Últimos 7 días
                          <v-icon size="18" right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item class="px-3" active-class="transparent" :ripple="false">
                          <v-list-item-title class="body-1"> Últimos 7 días</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="px-3" active-class="transparent" :ripple="false">
                          <v-list-item-title class="body-1"> Últimos 15 días</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="px-3" active-class="transparent" :ripple="false">
                          <v-list-item-title class="body-1"> Últimos 30 días</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                </v-expansion-panel-header>
                <v-divider v-if="panelTransactional === 0" />
                <v-expansion-panel-content class="px-5">
                  <v-row class="ma-0" justify="center" no-gutters>
                    <!-- documents issued -->
                    <v-col cols="4" class="text-center pt-8 pb-6">
                      <span class="subtitle-2 secondary--text mx-auto">Cantidad de DTEs</span>
                      <span class="d-block secondary--text font-weight-bold text-h4 mt-1 mb-10">2.746.953</span>
                      <div class="mx-auto" style="width: 60%; height: 100px">
                        <chart-line
                          class="mt-n12 ml-n5"
                          axe-type-y="number"
                          :background-color="'transparent'"
                          :chart-data="receivedChartData"
                          chart-id="documentsIssued"
                          :custom-labels="['Anterior', 'Actual']"
                          :height="90"
                          :number-rounded="true"
                          :show-axe-x="false"
                          :show-draw-border-grid-lines-axe-y="false"
                          :show-grid-lines-axe-y="false"
                          :show-point="true"
                          :borderWidth="1"
                          style="position:relative; height: 100%"
                        />
                      </div>
                    </v-col>
                    <!-- end documents issued -->
                    <v-divider vertical />
                    <!-- average ticket -->
                    <v-col cols="4" class="text-center pt-8 pb-6">
                      <span class="subtitle-2 secondary--text mx-auto">Total vendido</span>
                      <span class="d-block secondary--text font-weight-bold text-h4 mt-1 mb-10">$757.953<span class="fontDraw--text caption font-weight-medium">PE</span></span><!--{{$t('generals.CLP')}}-->
                      <div class="mx-auto" style="width: 60%; height: 100px">
                        <chart-line
                          class="mt-n12 ml-n5"
                          axe-type-y="number"
                          :background-color="'transparent'"
                          :chart-data="receivedChartData"
                          chart-id="documentsIssued"
                          :custom-labels="['Anterior', 'Actual']"
                          :height="90"
                          :number-rounded="true"
                          :show-axe-x="false"
                          :show-draw-border-grid-lines-axe-y="false"
                          :show-grid-lines-axe-y="false"
                          :show-point="true"
                          :borderWidth="1"
                          style="position:relative; height: 100%"
                        />
                      </div>
                    </v-col>
                    <!-- end average ticket -->
                    <v-divider vertical />
                    <!-- total sold -->
                    <v-col cols="4" class="text-center pt-8 pb-6">
                      <span class="subtitle-2 secondary--text mx-auto">Ticket promedio de venta</span>
                      <span class="d-block secondary--text font-weight-bold text-h4 mt-1 mb-10">$42.629<span class="fontDraw--text caption font-weight-medium">PE</span></span>
                      <div class="mx-auto" style="width: 60%; height: 100px">
                        <chart-line
                          class="mt-n12 ml-n5"
                          axe-type-y="number"
                          :background-color="'transparent'"
                          :chart-data="receivedChartData"
                          chart-id="documentsIssued"
                          :custom-labels="['Anterior', 'Actual']"
                          :height="90"
                          :number-rounded="true"
                          :show-axe-x="false"
                          :show-draw-border-grid-lines-axe-y="false"
                          :show-grid-lines-axe-y="false"
                          :show-point="true"
                          :borderWidth="1"
                          style="position:relative; height: 100%"
                        />
                      </div>
                    </v-col>
                    <!-- end total sold -->
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- pos -->
            <v-expansion-panels class="mt-5" v-model="panelPos">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="pa-0">
                      <span class="ml-n1 subtitle-2 secondary--text font-weight-bold align-center d-flex"><v-icon size="18" left color="secondary">mdi-cog</v-icon>Configuración del punto de venta</span>
                    </v-col>
                    <v-spacer />
                      <v-btn class="mr-4" @click="dialogConfigPos=true" outlined>Editar</v-btn>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-divider />
                  <v-list class="transparent v-list-form px-5">
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Última señal de vida</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}} <!-- de momento --></v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Instalado el</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Versión de {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">v0.9</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Versión del filtro {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        v0.1
                        <v-btn class="ml-2 mt-n1 body-1" color="primary" text :ripple="false">Actualizar<v-icon color="primary" right>mdi-autorenew</v-icon></v-btn>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">HMAC</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        <v-tooltip color="bgSearch" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-btn @click="toClipboard(instance.hmac || '')" text color="secondary" v-on="on" :ripple="false" style="justify-content: left;">{{instance.hmac || ''}}</v-btn>
                          </template>
                          <span class="d-block px-3 py-2">Copiar</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">Dinámico</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Modo de almacenamiento</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_scheme || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Copias PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.pdf_copies || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Forma de envío</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.sending_way || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento XML</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.irs_store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end pos -->

            <!-- folios -->
            <v-expansion-panels class="mt-5" v-model="panelFolios">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 secondary--text font-weight-bold text-capitalize align-center d-flex"><v-icon size="18" left color="secondary">mdi-file-document</v-icon>{{$t('generals.folios')}}</span>
                      <!-- <span class="body-1 secondary--text font-weight-bold text-capitalize">{{$t('generals.folios')}}
                        <v-tooltip color="bgSearch" right max-width="280px">
                          <template v-slot:activator="{on}">
                          <v-chip class="ml-2 pl-1 pr-2" v-on="on" color="primary" x-small>Demostración<v-icon right x-small>mdi-information-outline</v-icon></v-chip>
                          </template>
                          <span class="d-block pa-2">De momento no muestra la información de tu cuenta. Sin embargo, pronto podrás disfrutar de todos los beneficios.</span>
                        </v-tooltip>
                      </span> -->
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <v-empty-state class="pb-2" v-if="!currentList.length" type="documents" id="documentos" customClass="mt-n5" :isFree="true" :title="`Aún no existen ${$t('generals.folios')}`" :height="199" />
                  <v-sheet class="pa-0 transparent" rounded v-else>
                    <v-tabs class="mt-n1" color="primary" grow height="46" slider-size="3">
                      <v-tab class="body-2 secondary--text basic-tab" @click="activeFolio=true" exact :ripple="false">Activos</v-tab>
                      <v-tab class="body-2 secondary--text basic-tab" @click="activeFolio=false" exact :ripple="false">Usados</v-tab>
                    </v-tabs>
                    <v-data-table class="table-not-rounded" :headers="currentHeader" :items="currentList" hide-default-header disable-sort hide-default-footer>
                      <!-- header -->
                      <template v-slot:header="{ props: { headers } }">
                        <VTableHeaders :headers="headers" :sortable="false" />
                        <v-divider style="min-width: 100%; position: absolute;  margin-top: -6px; margin-left: -5px;" />
                      </template>
                      <!-- end header -->
                    </v-data-table>
                    <v-divider />
                    <v-btn color="primary" @click="$store.commit('base/SET_PRODUCT', 'folios'), $router.push({name: 'FoliosListCreate', params: {...$route.params } })" target="_blank" class=" mx-5 my-2 body-2" text>
                      Ver todos los tipos de documentos en
                      <v-avatar class="mx-1 pa-3" tile color="blackBlue" size="10" style="border-radius: 3px !important">
                        <img :src="require(`@/assets/product-explorer/icons/folios--icon.svg`)" min-width="10" />
                      </v-avatar>
                      Folios <v-icon right color="primary" size="14">mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end folios -->

            <!-- documents -->
            <v-expansion-panels class="mt-5" v-model="panelDocuments">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 secondary--text font-weight-bold align-center d-flex"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos relacionados</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider v-if="!loading" />
                  <documents-list :loading="loading" :show-pagination="false" />
                  <template>
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn color="primary" @click="$store.commit('base/SET_PRODUCT', 'documents'), $router.push({name: 'DocumentsList', params: {...$route.params } })" target="_blank" class=" mx-5 my-2 body-2" text>
                      Ver todos los tipos de documentos en
                        <v-avatar class="mx-1 pa-3" tile color="blackBlue" size="10" style="border-radius: 3px !important">
                          <img :src="require(`@/assets/product-explorer/icons/documental--icon.svg`)" min-width="10" />
                        </v-avatar>
                        Gestión Documental <v-icon right color="primary" size="14">mdi-open-in-new</v-icon>
                      </v-btn>
                      <!-- <v-btn class="ml-5 body-1" :to="{name: 'DocumentsList', params: $route.params, query: { pos_id: instance.id, name: instance.name || instance.id } }" :ripple="false" text color="primary">Ver todos los documentos</v-btn> -->
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end documents -->
          </div>
          <!-- end panels -->
        </div>
      </v-col>
    </template>
    <!-- dialog edit config Pos -->
    <v-dialog v-model="dialogConfigPos" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Editar configuración POS</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfigPos=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-3 px-5" style="max-height: 600px !important;">
          <v-list class="v-list-form-extended-medium py-0 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="row" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Lineal" value="radio-1"></v-radio>
                  <v-radio label="Dinámico" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Modo de almacenamiento</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="$v.editable.store_scheme.$mode" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Base de datos" value="radio-1"></v-radio>
                  <v-radio label="Directorio" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Copias PDF</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.editable.pdf_copies.$model" :error="$v.editable.pdf_copies.$error" :items="pdfcopiesList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Forma de envío</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-radio-group class="mx-0 mt-n4" v-model="editable.sending_way" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="XML" value="radio-1"></v-radio>
                  <v-radio label="ZIP" value="radio-2"></v-radio>
                </v-radio-group>
                <!-- <v-col cols="8">
                  <v-select v-model="$v.editable.sending_way.$model" :error="$v.editable.sending_way.$error" :items="sendingWayList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col> -->
              </v-list-item-subtitle>
            </v-list-item>
              <v-list-item>
            <v-list-item-title>Ruta de almacenamiento XML</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.store_path.$model" :error="$v.editable.store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Ruta de almacenamiento PDF</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.irs_store_path.$model" :error="$v.editable.irs_store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogConfigPos=false">Cancelar</v-btn>
          <v-btn color="primary" @click="setConfigPos">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit config Pos -->
  </v-row>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import ChartBar from '@/components/charts/ChartBar'
import branchListPE from '@/data/branchList' // example
import posListPE from '@/data/posList' // example
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'
import VCardMapDetail from "@/modules/pos/pos/components/complements/VCardMapDetail"
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    ChartBar,
    VEmptyState,
    MiniHeader,
    DocumentsList,
    ChartDoughnut,
    ChartLine,
    VCardMapDetail,
    VTableHeaders,
    VSkeleton,
    SkeletonList
  },
  data: () => ({
    upTime1: [
      {
        type: 'folios',
        startDate: '05 may. 2023',
        endDate: '15 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '15 may. 2023',
        endDate: '20 may. 2023',
        color: 'yellow',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '21 may. 2023',
        endDate: '25 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '26 may. 2023',
        endDate: '03 jun. 2023',
        color: 'success',
        totalDays: 0
      }
    ],
    upTime2: [
      {
        type: 'folios',
        startDate: '05 may. 2023',
        endDate: '10 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '11 may. 2023',
        endDate: '11 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '10 may. 2023',
        endDate: '13 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '14 may. 2023',
        endDate: '15 may. 2023',
        color: 'yellow',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '16 may. 2023',
        endDate: '20 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '21 may. 2023',
        endDate: '21 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '23 may. 2023',
        endDate: '25 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '26 may. 2023',
        endDate: '26 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '27 may. 2023',
        endDate: '02 jun. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '03 jun. 2023',
        endDate: '03 jun. 2023',
        color: 'yellow',
        totalDays: 0
      }
    ],
    upTime3: [
    {
        type: 'folios',
        startDate: '05 may. 2023',
        endDate: '08 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '09 may. 2023',
        endDate: '09 may. 2023',
        color: 'yellow',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '10 may. 2023',
        endDate: '16 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '16 may. 2023',
        endDate: '16 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '19 may. 2023',
        endDate: '23 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'certificado',
        startDate: '24 may. 2023',
        endDate: '25 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '26 may. 2023',
        endDate: '28 may. 2023',
        color: 'success',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '29 may. 2023',
        endDate: '30 may. 2023',
        color: 'yellow',
        totalDays: 0
      },
      {
        type: 'hardware',
        startDate: '02 may. 2023',
        endDate: '02 may. 2023',
        color: 'error',
        totalDays: 0
      },
      {
        type: 'seguridad',
        startDate: '03 may. 2023',
        endDate: '03 jun. 2023',
        color: 'success',
        totalDays: 0
      }
    ],
    status: [
      {
        type: 'Folios',
        title: 'Estado de los folios',
        startDate: '05 may. 2023',
        percent: 57,
        status: 'Normal',
        icon: 'file-multiple',
        color: 'success',
        description: 'de dias con folios',
        background: 'greenExtraLight',
        upTimeList: []
      },
      {
        type: 'Hardware',
        title: 'Estado del hardware',
        startDate: '05 may. 2023',
        percent: '83,6',
        color: 'yellow',
        status: 'Intermitencia',
        icon: 'chip',
        background: 'yellowLight',
        description: 'de días operacionales',
        upTimeList: []
      },
      {
        title: 'Estado de la seguridad',
        type: 'Seguridad',
        startDate: '05 may. 2023',
        percent: '93,5',
        color: 'success',
        status: 'Seguro',
        icon: 'lock',
        background: 'greenExtraLight',
        description: 'de dias operacionales',
        upTimeList: []
      },
      {
        type: 'Certificado digital',
        title: 'Estado de la certificado',
        startDate: '05 may. 2023',
        percent: '57',
        color: 'error',
        status: 'Intermitencia',
        totalDays: 0,
        icon: 'file-certificate',
        background: 'redLight',
        description: 'de dias operacionales',
        upTimeList: []
      }
    ],
    percentageOne: 30,
    percentageTwo: 20,
    percentageThree: 10,
    receivedChartData: {
      labels: [
        "Lun",
        "Mar",
        "Mie",
        "Jue",
        "Vie",
        "Sab",
        "Dom"
      ],
      datasets: [
        {
          label: "Anterior",
          percent_change: '20.84',
          backgroundColor: 'rgba(177, 201, 239, 1)',
          borderColor: '#0352CC',
          fill: true,
          data: [60, 33, 56, 10, 100, 50, 30]
        },
        {
          label: "Actual",
          percent_change: '-90.34',
          backgroundColor: 'rgba(230, 238, 250, 1)',
          borderColor: '#B1C9EF',
          fill: true,
          data: [50, 5, 22, 80, 10, 30, 50]
        }
      ]
    },
    panelTransactional: 0,
    instance: {},
    branchesList: [],
    scroll: 0,
    loadingPos: false,
    backupEditableBasicInfoPos: {},
    isSaveDisabled: false,
    showMoreStatistics: false,
    activeFolio: true,
    panelFolios: false,
    row: 'radio-1',
    show: false,
    key: 'dsa#$%^dr8485jfs6dasuydsadr8485jf$%s6das&*-@#$%^&uy',
    // example
    chartLineData: {
      datasets: [{
        data: [346, 254, 435, 567, 234, 344, 767, 576, 786, 712, 543, 456],
        label: 'current',
        backgroundColor: '#304FFE',
        percent_change: [-13.88, 8.7, 10.93, 1.58, 3.72, 9.38, 20.78, -18.08, 2.93, 5.13, -3.37, 6.64]
      }],
      labels: ['2021-05-01', '2021-06-01', '2021-07-01', '2021-08-01', '2021-09-01', '2021-10-01', '2021-11-01', '2021-12-01', '2022-01-01', '2022-02-01', '2022-03-01', '2022-04-01']
    },
    // end expample
    posImages: ['', ''],
    panelPos: false,
    panelDocuments: false,
    loading: false,
    dialogInfoPos: false,
    dialogConfigPos: false,
    editableBasicInfoPos: {
      name: '',
      internal_id: ''
    },
    editable: {
      store_scheme: 1,
      pdf_copies: 1,
      store_path: '',
      sending_way: '',
      irs_store_path: '',
      id: 'pos_oiruehr7ywhfrdsds'
      // internal_id: 'store_34'
      // branch_id: 'bra_dsadr8485jfs6dasuyrt'
    },
    //  store_scheme: 'Base de datos',
      // name: 'Pos 24',
      // pdf_copies: '2',
      // store_path: 'C:\/DTE\PROCESO\/XML\/[RUTEmisor]_[TipoDTE]_[Folio].xml',
      // store_path_pdf: '',
      // sending_way: 'XML',
      // irs_store_path: 'C:\/DTE\/PROCESO',
      // id: 'pos_oiruehr7ywhfrdsds',
      // internal_id: 'store_34',
      // branch_id: 'bra_dsadr8485jfs6dasuyrt'
    pdfcopiesList: ['1', '2', '3', '4', '5'],
    sendingWayList: ['xml', 'txt'],
    statusBreadcrumbs: [
      {
        text: 'mdi-store',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Tienda',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    posBreadcrumbs: [
      {
        text: 'mdi-printer-pos',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Pos',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    activeFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        actualFolio: '3',
        restantes: '8',
        asignado: '09 may. 2022 12:31'
      }
    ],
    activeHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Folio actual', value: 'actualFolio', align: 'end', sortable: false },
      { text: 'Restantes', value: 'restantes', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    usedFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr2',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr3',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      }
    ],
    usedHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    breadcrumbs: {
      main: 'Puntos de Ventas',
      children: []
    },
    upTimeList: []
   }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsCount,
      // instance: state => state.pos.posDetail,
      // posSequencesList: state => state.pos.posSequencesList,
      // branchesList: state => state.branches.branchesList,
      documentsList: state => state.documents.documentsList
    }),
    currentHeader () {
      return this.activeFolio ? this.activeHeaders : this.usedHeaders
    },
    currentList () {
      return this.activeFolio ? this.activeFolioList : this.usedFolioList
    },
    currentBranch () {
      return this.branchesList?.find(b => b.id === this.instance.branch) ?? ''
    },
    cardMapDetail () {
      return [
        {
          title: 'Nombre',
          icon: 'printer-pos',
          value: this.instance?.custom_id ?? '-'
        },
        {
          title: 'Tienda',
          icon: 'store',
          value: this.branchList?.name ?? '-',
          id: this.branchList.id
        },
        {
          title: 'ID',
          value: this.instance?.id ?? '-',
          isTooltip: true
        },
        {
          title: 'ID interno',
          value: this.instance?.custom_id ?? '-',
          isTooltip: true
        }
      ]
    },
    autoWidth () {
      if (this.$vuetify.breakpoint.width > 1600) return '20%'
      else if (this.$vuetify.breakpoint.width > 1300) return '30%'
      return '100% / 30'
    },
    autoHeight () {
      if (this.$vuetify.breakpoint.width > 1600) return 20
      else if (this.$vuetify.breakpoint.width > 1700) return 30
      return 15
    },
    currentColor () {
      return (item) => {
        item.children.forEach((elm) => {
          return elm
        })
      }
    }
  },
  watch: {
    panelDocuments (val) {
      if (val === 0) {
        // this.getList()
      }
    },
    panelFolios (val) {
      this.activeHeaders[3].text = this.$t(`generals.${this.activeHeaders[3].text}`)
      // if (val === 0) this.getPosSequencesList()
    }
  },
  async created () {
    this.loadingPos = true
    this.upTime1.forEach((item) => {
      item.totalDays = moment(item.endDate).diff(moment(item.startDate), 'days')
      item.colors = []
      for (let i = 0; i <= item.totalDays; i++) {
        item.colors[i] = item.color
      }
    })
    this.upTime1.forEach(({colors}) => {
      this.status[0].upTimeList = [...this.status[0].upTimeList, ...colors]
    })
    this.upTime2.forEach((item) => {
      item.totalDays = moment(item.endDate).diff(moment(item.startDate), 'days')
      item.colors = []
      for (let i = 0; i <= item.totalDays; i++) {
        item.colors[i] = item.color
      }
    })
    this.upTime2.forEach(({colors}) => {
      this.status[1].upTimeList = [...this.status[1].upTimeList, ...colors]
    })
    this.upTime3.forEach((item) => {
      item.totalDays = moment(item.endDate).diff(moment(item.startDate), 'days')
      item.colors = []
      for (let i = 0; i <= item.totalDays; i++) {
        item.colors[i] = item.color
      }
    })
    this.upTime3.forEach(({colors}) => {
      this.status[2].upTimeList = [...this.status[2].upTimeList, ...colors]
    })

    // await this.$store.dispatch('pos/RETRIEVE', {
    //   resource: 'pos',
    //   id: this.$route.params.id
    // })
    // this.loadingPos = false
    // if (!this.branchesList.length) {
    //   this.$store.dispatch('branches/LIST', {
    //     resource: 'branches',
    //     query: this.instance.branch
    //   })
    // }
    this.instance = posListPE.find(({id}) => id === this.$route.params.id)
    this.branchList = branchListPE.find(({id}) => id === this.instance.branch)
    setTimeout(() => {
      this.loadingPos = false
    }, 1000)
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async getList () {
      // if (!this.documentsList.length) {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            page_size: 6,
            pos_id: this.instance.id
          }
        })
        this.loading = false
      // }
    },
    getPosSequencesList () {
      this.$store.dispatch('pos/LIST', {
        resource: 'pos/sequences',
        query: {
          pos: this.instance.id
        }
      })
    },
    setBasicInfoPos () {
      this.$v.editableBasicInfoPos.$touch()
      if (this.$v.editableBasicInfoPos.$invalid) {
        return false
      }
      const payload = {
        name: this.editableBasicInfoPos.name,
        internal_id: this.editableBasicInfoPos.internal_id
      }

      this.updatePos(payload)
    },
    setConfigPos () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
    },
    updatePos (payload = {}) {
      this.$store.dispatch('pos/UPDATE', {
        resource: 'pos',
        id: this.$route.params.id,
        payload: payload
      })
      .then(({data}) => {
        this.editable = data
        this.$dialog.message.info('Los datos del Pos fueron actualizados correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogInfoPos = false
      })
    },
    handleEditConfigPos () {
      this.dialogConfigPos = true
      this.editable = {...this.instance}
      const config = ['store_scheme', 'pdf_copies', 'store_path', 'sending_way', 'irs_store_path']
      Object.keys(this.editable).forEach(key => {
        if (!config.includes(key)) {
          delete this.editable[key]
        }
      })
    },
    handleEditBasicInfoPos () {
      this.dialogInfoPos = true
      this.editableBasicInfoPos = {
        name: this.instance.name,
        internal_id: this.instance.internal_id ?? 'store_34'
      }
      this.backupEditableBasicInfoPos = this.editableBasicInfoPos
    }
  },
  validations () {
     const editableBasicInfoPos = {
      internal_id: { required },
      name: { required }
    }
    const editable = {
      store_scheme: { required, maxLength: maxLength(100) },
      pdf_copies: { required },
      store_path: { required, maxLength: maxLength(64) },
      sending_way: { required, maxLength: maxLength(254) },
      irs_store_path: { required, maxLength: maxLength(16) }
    }
    return {
      editableBasicInfoPos,
      editable
    }
  }
}
</script>